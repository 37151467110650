.body {
  padding: 0;
  margin: 0;

}

.main-loader {
  height: 100vh;
  background: linear-gradient(45deg, rgb(219, 222, 222), rgb(204, 199, 200));
}

.main_fader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: linear-gradient(45deg, rgb(219, 222, 222), rgb(204, 199, 200));
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      height: 300px;
      display: block;
      margin: 0 auto;
      path {
        animation-duration: 1s;
        animation-name: pulse;
        animation-iteration-count: infinite;
        color: #26a380;

        &.path_7 {
          animation-delay: -1s;
        }
        &.path_6 {
          animation-delay: -0.875s;
        }
        &.path_5 {
          animation-delay: -0.75s;
        }
        &.path_4 {
          animation-delay: -0.625s;
        }
        &.path_3 {
          animation-delay: -0.5s;
        }
        &.path_2 {
          animation-delay: -0.375s;
        }
        &.path_1 {
          animation-delay: -0.25s;
        }
        &.path-0 {
          animation-delay: -0.125s;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.1;
  }
}
