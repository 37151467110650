
.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-bottom: 1px solid;
  border-radius: 10px;
  width: 380px;
}

.gold {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-bottom: 1px solid;
  background: rgb(233, 190, 83);
  border-radius: 10px;
  width: 380px;
}

.silver {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-bottom: 1px solid;
  background: silver;
  border-radius: 10px;
  width: 380px;
}

.bronze {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-bottom: 1px solid;
  background: #cd7f32;
  border-radius: 10px;
  width: 380px;
}

.place {
  font-style: italic;
  font-weight: 700;
  font-size: 25px;
  width: 40px;
  align-items: end;
}

.name {
  width: 150px;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.points_main {
  font-size: 25px;
  display: flex;
  align-items: center;
  font-family: "Mitr";
  font-weight: 400;
  width: 45px;
  border-right: 1px dotted;
  border-left: 1px dotted;
  justify-content: center;
}

.points {
  display: flex;
  justify-content: center;
  font-family: "Mitr";
  width: 20px;

}
.points_big {
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-family: "Mitr";
  width: 50px;

}

.logo {
  height: 30px;
  width: 35px;
}

