.header {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

.button {
    height: 50px;
    width: 100px;
    background-color: rgb(192, 164, 7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    &:hover {
        cursor: pointer;
    }
}