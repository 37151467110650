.main {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 50px
}

.image_wrapper {
  max-height: 300px;
  max-width: 300px;
  :hover {
    cursor: pointer;
  }
}

.image {
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .main {
  flex-direction: column;}
}

