.outlet {
  max-width: 100%;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 75px ;
  gap: 350px;
  justify-content: center;
  margin: auto;
  
}

.header {
  width: 650px;
  max-width: 650px;
}

.body {
  width: 650px;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 500px) {
  .outlet {
  max-width: 100%}
  .body {
    max-width: 100%;
  }
  .header {
    max-width: 80%;
  }
}