.match {
  width: 350px;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  border-radius: 5px;
  justify-content: space-between;
}

.match_double {
  width: 350px;
  justify-content: space-between;

  display: flex;
  flex-direction: row;
  font-size: 20px;

  border-radius: 5px;
  background-color: rgb(248, 212, 6);
}

.team_name {
  width: 145px;
  display: flex;
  align-items: center;
}

.score {
  font-family: "Mitr";
  font-size: 400;
  width: 20px;
}
