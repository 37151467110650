.match {
  width: 350px;
  display: flex;
  flex-direction: row;
  font-size: 20px;
}

.team_name {
  width: 145px;
  display: flex;
  align-items: center;
}

.score {
  font-family: "Mitr";
  font-size: 400;
  width: 20px;
}
